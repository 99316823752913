<template>
    <div class="mainbox">
        <div class="mainboxs">

            <div style="justify-content: space-between;" class="c_item">
                <!-- <div class="mojor_img">
                    <img src="~@/assets/img/TechnicalCollege/69.png" alt="" />
                </div> -->
                <div class="mojor_detail">
                    <div class="mojor_til">{{ item.majorName }}</div>
                    <div class="mojor_item">
                        <div>
                            <div>招生类别：</div>
                            <div style="width: 160px" class="elp">{{ item.projectCategoryName }}</div>
                        </div>
                        <div>
                            <div>招生层次：</div>
                            <div style="width: 180px" class="elp">{{ item.level }}</div>
                        </div>
                        <div>
                            <div>学习形式：</div>
                            <div style="width: 160px" class="elp">{{ item.studyType }}</div>
                        </div>
                        <div>
                            <div>专业代码：</div>
                            <div style="width: 160px" class="elp">{{ item.majorCode }}</div>
                        </div>
                        <div>
                            <div>专业学科：</div>
                            <div style="width: 180px" class="elp">{{ item.firstSubject }}</div>
                        </div>
                        <div>
                            <div>专业门类：</div>
                            <div style="width: 160px" class="elp">{{ item.secondSubject }}</div>
                        </div>

                    </div>
                </div>
                <div @click="goBtn" class="mojor_btn">
                    <div>报名咨询</div>
                </div>
            </div>
            <div class="major_detail">
                <div class="major_detail_til"> <img style="width: 20px;height: auto;margin-right: 5px;"
                        src="~@/assets/img/TechnicalCollege/5.png" alt=""> 专业介绍</div>
                <div style="padding: 15px;line-height: 1.5;" v-html="ImgSizeLimit(item.majorIntroductory)"></div>
            </div>
            <div class="academy">
                <div class="academy_til"><img style="width: 30px;height: auto;margin-right: 5px;"
                        src="~@/assets/img/TechnicalCollege/108.png" alt="">{{ item.majorName }}招生院校</div>
                <div class="c_list">
                    <div @click="goNext(`/gxGathers/index?id=${item.id}`)" v-for="(item, index) in schoolList"
                        :key="index" class="c_item">
                        <div style="width: 130px;height: 130px;" class="mojor_img">
                            <img :src="item.schoolBadge" alt="">
                        </div>
                        <div class="mojor_detail">
                            <div class="mojor_til">{{ item.schoolName }}</div>
                            <div class="mojor_item">
                                <div>
                                    <div>学校性质：</div>
                                    <div style="width: 160px;" class="elp">{{ item.schoolNatureName }}</div>
                                </div>
                                <div>
                                    <div>招生类别：</div>
                                    <div style="width: 180px;" class="elp">{{ item.projectCategoryName }}</div>
                                </div>
                                <div>
                                    <div>院校特性：</div>
                                    <div style="width: 160px;" class="elp">{{ item.characteristic }}</div>
                                </div>
                                <div>
                                    <div>招生层次：</div>
                                    <div style="width: 640px;" class="elp">{{ item.level }}</div>
                                </div>

                                <div>
                                    <div>学校简介：</div>
                                    <div style="width: 640px;" v-html="item.introductory" class="elp"></div>
                                </div>
                            </div>
                        </div>
                        <div class="mojor_btn">
                            <div>查看学校</div>
                        </div>
                    </div>
                    <el-empty v-if="!schoolList || schoolList.length == 0" description="暂无数据"></el-empty>

                </div>
                <el-pagination style="display: flex; justify-content: center" class="pagination partner-pagination"
                    :current-page="pageNum1" :page-size="pageSize1" :page-sizes="[10, 20, 50, 100]"
                    layout="total, sizes, prev, pager, next" :total="total1" :page-count="4"
                    @current-change="handleCurrentChange1" @size-change="handleSizeChange1" />
            </div>
            <div class="question">
                <div class="question_til"><img style="width: 30px;height: auto;margin-right: 5px;"
                        src="~@/assets/img/TechnicalCollege/6.png" alt="">{{ item.majorName }}常见问题</div>
                <div class="question_list">
                    <div v-for="(item, index) in questionList" :key="index" class="question_item">
                        <div class="q_ask">
                            <div>Q</div>
                            <div>{{ item.questionName }}</div>
                        </div>
                        <div class="q_answer">
                            <div>A</div>
                            <div>
                                {{ item.questionAnswer }} </div>
                        </div>
                    </div>
                    <el-empty v-if="!questionList || questionList.length == 0" description="暂无数据"></el-empty>

                </div>
                <el-pagination style="display: flex; justify-content: center" class="pagination partner-pagination"
                    :current-page="pageNum" :page-size="pageSize" :page-sizes="[10, 20, 50, 100]"
                    layout="total, sizes, prev, pager, next" :total="total" :page-count="4"
                    @current-change="handleCurrentChange" @size-change="handleSizeChange" />
            </div>


        </div>
        <submit :intentionMajor='item.majorName' :majorId='item.majorId' v-if="projectType == 0 && item.majorName" />
        <submit1 :intentionMajor='item.majorName' :majorId='item.majorId' v-if="projectType == 1 && item.majorName" />
        <submit2 :intentionMajor='item.majorName' :majorId='item.majorId' v-if="projectType == 5 && item.majorName" />
    </div>
</template>

<script>
import { Know } from "@/api/know";
let know = new Know();
import { getDomain } from "@/api/cookies";
import searchs from "../components/search.vue";
import connects from "./connect.vue";
import submit from "@/views/gxGather/school/pages/submit.vue";
import submit1 from "@/views/gxGather/graduate/submit.vue";
import submit2 from "@/views/gxGather/abroad/submit.vue";

export default {
    components: { searchs, connects, submit, submit1, submit2 },
    data() {
        return {
            is_huadong: true,
            pageSize: 10,
            pageNum: 1,
            total: 0,
            pageSize1: 4,
            pageNum1: 1,
            total1: 0,
            item: {},
            questionList: [],
            schoolList: [],
            projectType: ''
        };
    },
    created() {
        this.projectType = localStorage.getItem('projectType');

        this.majorId = this.$route.query.majorId;
        this.getTuitionMajorData();
        this.selectMajorSchoolPcList();
        this.selectMajorProblemPcList();
    },
    mounted() { },
    methods: {
        getTuitionMajorData() {
            know.getTuitionMajorData({
                majorId: this.majorId,
            }).then((res) => {
                this.item = res.data
            });
        },
        selectMajorSchoolPcList() {
            know.selectMajorSchoolPcList({
                pageSize: this.pageSize1,
                pageNum: this.pageNum1,
                majorId: this.majorId,
                projectType: 0
            }).then((res) => {
                this.schoolList = res.rows
                this.total1 = res.total
            });
        },
        selectMajorProblemPcList() {
            know.selectMajorProblemPcList({ projectType: 0, pageSize: this.pageSize, pageNum: this.pageNum, majorId: this.majorId }).then((res) => {
                if (res.rows) {
                    this.questionList = res.rows
                    this.total = res.total
                }
            });
        },
        handleSizeChange(val) {
            this.pageNum = 1;
            this.pageSize = val;
            this.selectMajorProblemPcList();
        },
        handleCurrentChange(val) {
            this.pageNum = val;
            this.selectMajorProblemPcList();
        },
        handleSizeChange1(val) {
            this.pageNum1 = 1;
            this.pageSize1 = val;
            this.selectMajorSchoolPcList();
        },
        handleCurrentChange1(val) {
            this.pageNum1 = val;
            this.selectMajorSchoolPcList();
        },
        goBtn() {
            window.scrollTo({
                top: document.body.scrollHeight,
                behavior: 'smooth' // 平滑滚动
            });
        }

    },
};
</script>

<style lang="less" scoped>
.mainbox {
    background: #F5F7F9;
    border-radius: 3px 3px 3px 3px;
    margin-top: 20px;
    padding: 30px;
    padding-top: 0;

    .mainboxs {
        width: 1200px;
        margin: 0 auto;
        border-radius: 3px 3px 3px 3px;
    }

    .c_item {
        padding: 30px 20px;
        display: flex;
        align-items: flex-end;
        cursor: pointer;
        background: #FFFFFF;
        border-radius: 3px 3px 3px 3px;


        .mojor_img {
            width: 203px;
            height: 132px;
            border-radius: 8px 8px 8px 8px;
            overflow: hidden;
        }

        .mojor_detail {
            width: 810px;
            margin-left: 20px;



            .mojor_til {
                font-family: MicrosoftYaHei-Bold, MicrosoftYaHei-Bold;
                font-weight: bold;
                font-size: 20px;
                color: #333333;
                margin-bottom: 10px;
                display: flex;
                align-items: center;

                span {

                    font-family: MicrosoftYaHei, MicrosoftYaHei;
                    font-weight: normal;
                    font-size: 12px;
                    color: #666666;
                }
            }

            .mojor_item {
                display: flex;
                flex-wrap: wrap;

                >div {
                    display: flex;
                    font-family: MicrosoftYaHei, MicrosoftYaHei;
                    font-weight: normal;
                    font-size: 14px;
                    color: #666666;
                    margin-right: 20px;
                    margin-top: 13px;

                    >div:nth-child(1) {
                        flex-shrink: 0;
                        color: #555555;
                        margin-right: 5px;
                        font-weight: bold;
                    }
                }

            }


        }

        .mojor_btn {
            height: 90px;
            border-left: 1px solid #EEEEEE;
            display: flex;
            align-items: center;

            >div {
                width: 112px;
                height: 40px;
                background: #1061FF;
                border-radius: 20px 20px 20px 20px;
                font-family: MicrosoftYaHei, MicrosoftYaHei;
                font-weight: normal;
                font-size: 14px;
                color: #FFFFFF;
                cursor: pointer;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-left: 34px;

                &:hover {
                    background: #3D6CC8;
                }
            }


        }

        &:hover {
            background: rgba(16, 97, 255, 0.1);

            .mojor_til {
                color: #3D6CC8;
            }
        }
    }

    .major_detail {

        background: #FFFFFF;
        border-radius: 3px 3px 3px 3px;
        padding: 25px;
        margin-top: 20px;

        .major_detail_til {
            display: flex;
            align-items: center;

            font-family: MicrosoftYaHei-Bold, MicrosoftYaHei-Bold;
            font-weight: bold;
            font-size: 18px;
            color: #333333;
        }
    }

    .academy {
        background: #FFFFFF;
        border-radius: 3px 3px 3px 3px;
        padding: 30px;
        margin-top: 20px;

        .academy_til {
            display: flex;
            align-items: center;
            justify-content: center;
            font-family: MicrosoftYaHei-Bold, MicrosoftYaHei-Bold;
            font-weight: bold;
            font-size: 26px;
            color: #444444;
        }

        .c_list {
            margin-top: 20px;

            .c_item {
                border-top: 1px solid #EEEEEE;
            }
        }
    }

    .question {

        background: #FFFFFF;
        border-radius: 3px 3px 3px 3px;
        margin-top: 20px;
        padding: 30px;

        .question_til {
            display: flex;
            align-items: center;
            justify-content: center;
            font-family: MicrosoftYaHei-Bold, MicrosoftYaHei-Bold;
            font-weight: bold;
            font-size: 26px;
            color: #444444;
        }

        .question_list {
            .question_item {
                margin-top: 25px;

                .q_ask {
                    display: flex;
                    align-items: baseline;

                    >div:nth-child(1) {
                        width: 20px;
                        height: 20px;
                        background: #FECD61;
                        border-radius: 3px 3px 3px 3px;
                        text-align: center;
                        line-height: 20px;
                        font-family: MicrosoftYaHei, MicrosoftYaHei;
                        font-weight: normal;
                        font-size: 15px;
                        color: #333333;
                        flex-shrink: 0;

                    }

                    >div:nth-child(2) {
                        padding: 5px 20px;
                        background: #FECD61;
                        border-radius: 15px 15px 15px 15px;
                        font-family: MicrosoftYaHei, MicrosoftYaHei;
                        font-weight: normal;
                        font-size: 15px;
                        color: #333333;
                        margin-left: 10px;
                    }
                }

                .q_answer {
                    display: flex;
                    align-items: baseline;
                    margin-top: 12px;

                    >div:nth-child(1) {
                        width: 20px;
                        height: 20px;
                        background: #E3E3E3;
                        border-radius: 3px 3px 3px 3px;
                        text-align: center;
                        line-height: 20px;
                        font-family: MicrosoftYaHei, MicrosoftYaHei;
                        font-weight: normal;
                        font-size: 15px;
                        color: #333333;
                        flex-shrink: 0;
                    }

                    >div:nth-child(2) {
                        font-family: MicrosoftYaHei, MicrosoftYaHei;
                        font-weight: normal;
                        font-size: 14px;
                        color: #555555;
                        margin-left: 10px;
                    }
                }
            }
        }
    }

}

img {
    width: 100%;
    height: 100%;
    display: block;
}
</style>